// review-panel
#review-panel {
  background-repeat: no-repeat;
  background-size: cover;
}
#review-panel .container,
#review-panel .htmlPanel {
  max-width: 1200px;
}
.rotatingReviewItems .slick-slide {
  margin-right: 20px;
  margin-left: 20px;
  background: white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 50px;
  border-bottom: 0.3rem solid #c2282d;
}
#review-panel .rotatingReviewItems .slick-prev::before,
#review-panel .rotatingReviewItems .slick-next::before {
  color: white;
  padding: 0;
}
#review-panel .htmlPanel p {
  max-width: 840px;
  margin: 0;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  line-height: 1.5;
  margin-bottom: 50px;
  @media (min-width: 576px) {
    margin-bottom: 75px;
  }
}
.rotatingReviewItems .starsImage {
  width: 40%;
}
