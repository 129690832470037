.main-content {
  padding-top: map-get($top-bar-height, mobile);

  @include media-breakpoint-up(lg) {
    padding-top: map-get($top-bar-height, desktop);
  }
}

.actions {
  margin: 1rem 0;
  text-align: center;
  border-radius: 0;
  button {
    margin-right: 0.7rem;
    background-color: $link-color;
    border-color: $link-color;
    color: white;
    font-weight: $weight-bold;
    padding: 0.4rem 1rem;
    border-radius: 7px;
    &:hover {
      background-color: $link-hover-color;
      border-color: $link-hover-color;
    }
  }
}
